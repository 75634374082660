import React, {useEffect, useRef} from "react"
import Single from "../../single"
import info1 from "../../../../static/img/irstrat/articulo8.png";
import noticias13 from "../../../../static/img/blog/noticias-13-.png";

const Detail = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })

    return (
        <Single location={props.location} title='La inversión en tecnología de RI disminuye a pesar de su uso incremental'>
            <div className="main_blog_items">
                <div className="main_blog_item">
                    <div className="main_blog_image img_hover">
                        <img alt={" "} src={noticias13}/>
                    </div>
                    <div className="main_blog_text">
                        <div className="post_info">
                            <a href="#" className="date">
                                11 Dic. 2020
                            </a>
                            <div className="blog_author_area">
                 <span>
                  Por : Ricardo Ávila
                </span>
                                <span>
                    Comunicación
                </span>
                            </div>
                        </div>

                        <h2>La inversión en tecnología de RI disminuye a pesar de su uso incremental </h2>
                        <br/>
                    </div>
                </div>
                <div className="s_main_text">
                        <p>De acuerdo con la encuesta global de la revista IR Magazine, realizada en el 3T20, al compararse con
                        los últimos dos años los equipos de RI han manifestado un uso incremental de herramientas
                        tecnológicas para la realización de sus actividades. Lo anterior es un hecho que, sin duda,se ha vuelto
                        más perceptible a raíz de la pandemia de COVID-19, ya que, como consecuencia de las restricciones
                        a la movilidad, los participantes del área de RI han tenido que depender cada vez en mayor medida
                        de la efectividad de dichos instrumentos.  </p>

                        <p>En este respecto, las herramientas tecnológicas preferidas por los directores de relación con
                        inversionistas (IROs, por sus siglas en inglés) son los sitios web de RI, las herramientas de
                        videoconferencia y los servicios de noticias, predilección que podría explicarse por el compromiso
                        que tiene este departamento con una oportuna y efectiva comunicación.</p>

                        <p>Sin embargo, la información obtenida de la encuesta realizada por la revista IR Magazine indica que,
                        durante los últimos dos años los presupuestos para tecnología de RI han ido disminuyendo, pues si
                        bien la inversión media se mantiene entre USD$20,000 – USD$49,000, una mayor proporción de los
                        equipos de RI (36%) informa haber destinado menos de USD$20,000 en tecnología en comparación
                        con 2018 (28%) y menos departamentos de RI (37%) invirtieron más de USD$50,000 vs. 2018 (43%).  </p>

                        <img className="w-100 wow fadeIn" src={info1} alt="" data-wow-delay="0.2s" data-wow-duration="2.0s"/>

                        <p>Estos números señalan que los equipos de RI están maximizando el aprovechamiento de los sitios
                        web y las videoconferencias, pues como nos muestra la gráfica de arriba el 97% y 42% de los IROs
                        que regularmente emplean las herramientas de videoconferencia y los sitios de RI (la herramienta
                          más utilizada), respectivamente, han incrementado su uso en los últimos dos años.  </p>

                        <p>Este comportamiento deriva de que la capacidad de informar óptimamente, entre otras cosas, la
                        historia, modelo de negocio y tesis de inversión de cada compañía es fundamental para la atracción
                        de inversores. Además, la apropiada realización de esta actividad coadyuva a aumentar de manera
                        significativa la agilidad y capacidad de acceso de la empresa a financiamiento bajo mejores
                        condiciones (tasa y plazo), así como a incentivar al alza el precio de la acción (para las compañías
                          públicas) por la confianza que se transmite al mercado.</p>

                        <p>Referencias Bibliográficas
                          <ul>
                            <li>Byrne,C. (2020). IR tech spend decreases, despite increased usage. Noviembre 20,2020.  <a href="https://www.irmagazine.com/technology-social-media/ir-tech-spend-decreases-despite-increased-usage-4" target="_blank">IR Magazine</a>.</li>
                            <li>Technology & IR Sample 2020, Noviembre 3, 2020. <a href="https://content.irmagazine.com/story/technology-and-ir-sample/page/1" target="_blank">IR Magazine</a>.</li>
                          </ul>
                        </p>

                </div>
              </div>
        </Single>
    )
}

export default Detail
